import {
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditPopup = ({ styles, edit, setEdit, editRef, selectedItem }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const user_id = selectedItem.id;

  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .max(254, "Email is too long"),
    first_name: Yup.string()
      .max(150, "First name is too long")
      .required("First name is required"),
    last_name: Yup.string()
      .max(150, "Last name is too long")
      .required("Last name is required"),
    phone: Yup.string(),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("user"))?.access;

      const response = await axios.patch(
        `${apiUrl}/api/v1/auth/update/${user_id}/`,
        {
          phone: values.phone,
          address: values.address,
          timezone: values.timezone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setNotificationMessage("User updated successfully");
        setTimeout(() => {
          setNotificationMessage(false);
        }, 3000);
        setEdit(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setIsSubmitting(false);
      setNotificationMessage("Error updating user");
      setTimeout(() => {
        setNotificationMessage(false);
      }, 3000);
    }
  };

  const styles2 = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      <CSSTransition in={edit} classNames={styles} timeout={300} unmountOnExit>
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="fixed top-4 bg-white p-4 w-[370px] rounded-md">
            <div className="gap-4 flex items-center">
              <FontAwesomeIcon
                className="text-danger"
                icon={faExclamationTriangle}
              />
              <h5>You can't change the email or phone number of this user.</h5>
            </div>
          </div>
          <div
            ref={editRef}
            className="bg-white w-96 p-6 rounded-md shadow-lg relative"
          >
            <button
              onClick={() => setEdit(false)}
              className="absolute top-4 right-4"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="text-lg font-bold mb-4">Edit Manager Details</h2>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      readOnly="true"
                      value={selectedItem.email}
                      className={`border w-full rounded-md bg-[#F9FAFB] text-slate-500 p-2 ${
                        errors.email && touched.email
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="first_name"
                    >
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="first_name"
                      name="first_name"
                      className={`border w-full rounded-md p-2 ${
                        errors.first_name && touched.first_name
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="last_name"
                    >
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="last_name"
                      name="last_name"
                      className={`border w-full rounded-md p-2 ${
                        errors.last_name && touched.last_name
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"gb"}
                      value={selectedItem.phone || ""}
                      onChange={() => {}}
                      inputStyle={{
                        width: "100%",
                        backgroundColor: "#F9FAFB",
                        border:
                          errors.phone && touched.phone
                            ? "1px solid #E53E3E"
                            : "1px solid #D1D5DB",
                        color: "#6B7280",
                        pointerEvents: "none",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      enableSearch={false}
                      countryCodeEditable={false}
                    />
                  </div>

                  {isSubmitting ? (
                    <button type="submit" disabled className="mt-8 w-[100%]">
                      <section className="dots-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </section>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-btn hover:bg-orange-600 mt-8 w-full text-white rounded-lg py-2"
                    >
                      Save
                    </button>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={notificationMessage}
        classNames={styles2}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{notificationMessage}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default EditPopup;
