import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Register Chart.js components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

const KpiCompletionBreakdown = () => {
  const [loading, setLoading] = useState(false);
  const [managersData, setManagersData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: ["Completed", "In Progress", "Undone"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [
          "rgb(102, 193, 220)",
          "rgb(134, 122, 247)",
          "rgb(234, 56, 36)",
        ],
      },
    ],
  });
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const navigate = useNavigate();

  const fetchKpis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/tracker/targets/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const kpiData = response.data;

      const groupedManagers = kpiData.reduce((acc, kpi) => {
        const managerId = kpi.assignee.id;
        if (!acc[managerId]) {
          acc[managerId] = {
            id: managerId,
            name: `${kpi.assignee.last_name} ${kpi.assignee.first_name}`,
            status: kpi.status,
            startDate: kpi.start_date,
            percentageCompleted: kpi.percentage_completed,
          };
        } else {
          acc[managerId].status = kpi.status;
          acc[managerId].startDate = kpi.start_date;
          acc[managerId].percentageCompleted = kpi.percentage_completed;
        }
        return acc;
      }, {});

      setManagersData(Object.values(groupedManagers));

      const totalTasks = kpiData.length;
      const completedTasks = kpiData.filter(
        (kpi) => kpi.actual_completed === kpi.total_expected
      ).length;
      const undoneTasks = kpiData.filter(
        (kpi) => kpi.actual_completed === 0
      ).length;

      setChartData({
        labels: ["Completed", "Undone", "In Progress"],
        datasets: [
          {
            data: [
              completedTasks,
              undoneTasks,
              totalTasks - completedTasks - undoneTasks,
            ],
            backgroundColor: [
              "rgb(102, 193, 220)",
              "rgb(234, 56, 36)",
              "rgb(134, 122, 247)",
            ],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching KPIs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case "Completed":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "In Progress":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <>
      <div className="bg-white p-4 mb-3 rounded-md shadow-sm">
        <h2 className="font-bold text-sm mb-6">KPI Completion Breakdown</h2>
        <div style={{ maxWidth: "350px" }}>
          <Doughnut data={chartData} options={{ maintainAspectRatio: true }} />
        </div>
      </div>

      <div className="mb-24">
        <div className="overflow-x-auto bg-white">
          <table className="rounded-md shadow-md w-full">
            <thead className="bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                  Manager
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                  Start Date
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                  Completion Rate
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {loading ? (
                <tr>
                  <td
                    colSpan="4"
                    className="text-center px-6 py-4 text-[13px] text-gray-500"
                  >
                    Loading KPIs...
                  </td>
                </tr>
              ) : managersData.length > 0 ? (
                managersData.map((manager) => (
                  <tr
                    key={manager.id}
                    onClick={() => navigate(`/managers/${manager.id}`)}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.name}
                    </td>
                    <td>
                      <span
                        className={`px-3 py-1 rounded-full whitespace-nowrap text-xs font-medium ${getStatusClass(
                          manager.status || "In Progress"
                        )}`}
                      >
                        {manager.status || "In Progress"}
                      </span>
                    </td>

                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.startDate}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.percentageCompleted}%
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="text-center px-6 py-4 text-[13px] text-gray-500"
                  >
                    No managers found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default KpiCompletionBreakdown;
