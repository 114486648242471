import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faTimes,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useParams } from "react-router-dom";

const ManagerTasks = () => {
  const localizer = momentLocalizer(moment);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showNotification, setshowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const { id } = useParams();

  const fetchKpis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/tracker/targets/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const kpiData = response.data;
      const filteredKpis = kpiData.filter((kpi) => kpi.assignee.id === id);

      const mappedTasks = filteredKpis.map((kpi) => ({
        id: kpi.id,
        title: kpi.name,
        start: new Date(kpi.start_date),
        end: new Date(kpi.due_date),
        description: kpi.description,
        status: kpi.percentage_completed === 100 ? "completed" : "in-progress",
        percentage_completed: kpi.percentage_completed,
        assigned_by: `${kpi.assigned_by.first_name} ${kpi.assigned_by.last_name}`,
        assignee: `${kpi.assignee.first_name} ${kpi.assignee.last_name}`,
        challenges: kpi.challenges,
        notes: kpi.notes,
        total_expected: kpi.total_expected,
        actual_completed: kpi.actual_completed,
      }));

      setTasks(mappedTasks);
    } catch (error) {
      console.error("Error fetching KPIs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchKpis when the component mounts
  useEffect(() => {
    fetchKpis();
  }, [id]);

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedTask(event);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/tracker/targets/${selectedTask.id}`,
        { ...selectedTask },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setshowNotification("Task updated successfully!");
      setTimeout(() => {
        setshowNotification(false);
      }, 3000);
      fetchKpis();
      closePopup();
    } catch (error) {
      console.error("Error updating task:", error);
      setErrorNotification("Failed to update the task.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${apiUrl}/api/v1/tracker/targets/${selectedTask.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setshowNotification("Task deleted successfully!");
      setTimeout(() => {
        setshowNotification(false);
      }, 3000);
      fetchKpis();
      closePopup();
    } catch (error) {
      console.error("Error deleting task:", error);
      setErrorNotification("Failed to delete the task.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  return (
    <div className="mb-24 bg-white mt-[24px] rounded-md shadow-sm">
      <h2 className="text-md font-bold mb-4 p-4">Task Calendar</h2>

      {loading ? (
        <p className="text-center">Loading tasks...</p>
      ) : (
        <Calendar
          localizer={localizer}
          events={tasks}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "80vh" }}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={(event) => {
            const statusColors = {
              completed: "#34d399",
              "in-progress": "#fbbf24",
            };
            return {
              style: {
                backgroundColor: statusColors[event.status] || "#60a5fa",
                color: "white",
                borderRadius: "4px",
                padding: "4px",
              },
            };
          }}
        />
      )}

      {selectedTask && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm">
          <div className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8">
            <button onClick={closePopup} className="flex justify-self-end mb-4">
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="text-center">
              <h3 className="text-3xl font-bold text-gray-800 mb-4">
                {selectedTask.title}
              </h3>
              <span
                className={`inline-block px-4 py-1 text-sm font-semibold rounded-full ${
                  selectedTask.status === "completed"
                    ? "bg-green-100 text-green-700"
                    : selectedTask.status === "in-progress"
                    ? "bg-yellow-100 text-yellow-700"
                    : "bg-red-100 text-red-700"
                }`}
              >
                {selectedTask.status || "No Status"}
              </span>
            </div>

            <div className="mt-6 space-y-4">
              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">Description:</span>
                <span className="text-gray-800">
                  {selectedTask.description || "N/A"}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">Assigned By:</span>
                <span className="text-gray-800">
                  {selectedTask.assigned_by
                    ? `${selectedTask.assigned_by}`
                    : "N/A"}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">Assignee:</span>
                <span className="text-gray-800">
                  {selectedTask.assignee ? `${selectedTask.assignee}` : "N/A"}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">Completion:</span>
                <span className="text-gray-800">
                  {selectedTask.percentage_completed}%
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">Start Date:</span>
                <span className="text-gray-800">
                  {moment(selectedTask.start).format("MMMM Do YYYY, h:mm a")}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-600">End Date:</span>
                <span className="text-gray-800">
                  {moment(selectedTask.end).format("MMMM Do YYYY, h:mm a")}
                </span>
              </div>
            </div>

            <div className="mt-6 flex justify-around gap-2">
              <button
                onClick={handleUpdate}
                className="px-6 flex items-center gap-2 py-2 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 transition duration-300"
              >
                <FontAwesomeIcon icon={faEdit} />
                Update
              </button>
              <button
                onClick={handleDelete}
                className="px-6 py-2 flex items-center gap-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 transition duration-300"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ManagerTasks;
