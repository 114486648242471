import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeft, faBullseye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import KpiCompletionBreakdown from "../Components/KpiCompletionBreakdown";
import AddKpi from "../Components/AddKpi";
import PeriodContextSelector from "../Components/PeriodContextSelector";

const Kpi = () => {
  const [target, setTarget] = useState(false);
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: 0,
  });

  const navigate = useNavigate();
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const styles = {
    enter: "transform translate-y-full opacity-0",
    enterActive:
      "transform -translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    const fetchQuickStats = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/targets/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const kpiData = response.data;

        const completedTasks = kpiData.filter(
          (kpi) => kpi.actual_completed === kpi.total_expected
        ).length;
        const undoneTasks = kpiData.filter(
          (kpi) => kpi.actual_completed === 0
        ).length;

        const totalExpected = kpiData.reduce(
          (sum, kpi) => sum + (kpi.total_expected || 0),
          0
        );
        const totalCompleted = kpiData.reduce(
          (sum, kpi) => sum + (kpi.actual_completed || 0),
          0
        );

        const completionRate = (totalCompleted / totalExpected) * 100;

        setQuickStats({
          totalKpis: kpiData.length,
          completed: completedTasks,
          undone: undoneTasks,
          completionRate: completionRate.toFixed(2),
        });
      } catch (error) {
        console.error("Error fetching quick stats:", error);
      }
    };

    fetchQuickStats();
  }, [apiUrl, token]);

  return (
    <>
      <div className="bg-[#F8F9FC] p-4">
        <div className="bg-white flex items-center justify-between p-4 mb-3 rounded-md shadow-sm">
          <span className="flex items-center gap-2">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="font-bold text-sm">KPI Dashboard</h2>
          </span>

          <button
            onClick={() => setTarget(true)}
            className="bg-btn rounded-md px-4 py-2 text-white"
          >
            Set Target &nbsp;
            <FontAwesomeIcon icon={faBullseye} />
          </button>
        </div>

        <PeriodContextSelector />

        <div className="bg-white p-4 mb-3 rounded-md shadow-sm">
          <div className="flex items-center justify-between">
            <h2 className="font-bold text-sm">Quick Stats</h2>
            <div className="relative w-[160px]">
              <button
                className="bg-white border w-full border-gray-300 rounded-md shadow-sm px-2 py-2 focus:outline-none"
                type="button"
              >
                All Managers
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-[24px]">
            <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
              <span className="text-gray-500 text-xs">Total KPIs</span>
              <span className="text-gray-900 text-2xl font-bold">
                {quickStats.totalKpis}
              </span>
            </div>

            <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
              <span className="text-gray-500 text-xs">Completed</span>
              <span className="text-gray-900 text-2xl font-bold">
                {quickStats.completed}
              </span>
            </div>

            <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
              <span className="text-gray-500 text-xs">Undone</span>
              <span className="text-gray-900 text-2xl font-bold">
                {quickStats.undone}
              </span>
            </div>

            <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
              <span className="text-gray-500 text-xs text-center">
                Completion Rate
              </span>
              <span className="text-gray-900 text-2xl font-bold">
                {quickStats.completionRate}%
              </span>
            </div>
          </div>
        </div>

        <KpiCompletionBreakdown />
        <AddKpi styles={styles} target={target} setTarget={setTarget} />
      </div>
    </>
  );
};

export default Kpi;
