import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CSSTransition } from "react-transition-group";

const Popup = ({ styles, popup, setEdit, setErase, popupRef }) => {
  return (
    <>
      <CSSTransition in={popup} classNames={styles} timeout={500} unmountOnExit>
        <div className="fixed inset-0 z-20 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div
            ref={popupRef}
            className="bg-white p-4 h-auto max-h-[90vh] w-full fixed bottom-0 right-0 rounded-tl-lg rounded-tr-lg shadow-md overflow-y-auto"
          >
            <div className="flex flex-col gap-4">
              <button
                onClick={() => setEdit(true)}
                className="border bg-blue-400 text-white font-bold flex items-center gap-2 justify-center hover:bg-blue-600 transition-all ease-in-out w-full py-2 px-4 rounded-md"
              >
                <FontAwesomeIcon icon={faPen} />
                Edit User
              </button>
              <button
                onClick={() => setErase(true)}
                className="bg-red-500 text-white font-bold flex items-center gap-2 justify-center hover:bg-red-800 transition-all ease-in-out w-full py-2 px-4 rounded-md"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete User
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Popup;
