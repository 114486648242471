import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const PeriodContextSelector = () => {
  const [periodType, setPeriodType] = useState("week"); // 'week' or 'month'
  const [currentDate, setCurrentDate] = useState(new Date());

  const getPeriodLabel = () => {
    if (periodType === "week") {
      const start = startOfWeek(currentDate);
      const end = endOfWeek(currentDate);
      return `Week of ${format(start, "MMM d")} - ${format(
        end,
        "MMM d, yyyy"
      )}`;
    } else {
      return format(currentDate, "MMMM yyyy");
    }
  };

  const getPeriodRange = () => {
    if (periodType === "week") {
      return {
        start: startOfWeek(currentDate),
        end: endOfWeek(currentDate),
      };
    } else {
      return {
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate),
      };
    }
  };

  const navigatePeriod = (direction) => {
    const newDate = new Date(currentDate);
    if (periodType === "week") {
      newDate.setDate(newDate.getDate() + direction * 7);
    } else {
      newDate.setMonth(newDate.getMonth() + direction);
    }
    setCurrentDate(newDate);
  };

  return (
    <div className="w-full">
      {/* Period Type Toggle */}
      <div className="flex items-center mt-[24px] justify-between p-4 bg-white rounded-lg shadow-sm border mb-4">
        <div className="flex space-x-4">
          <button
            onClick={() => setPeriodType("week")}
            className={`px-4 py-2 rounded-md ${
              periodType === "week"
                ? "bg-blue-100 text-blue-700 font-medium"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            Weekly
          </button>
          <button
            onClick={() => setPeriodType("month")}
            className={`px-4 py-2 rounded-md ${
              periodType === "month"
                ? "bg-blue-100 text-blue-700 font-medium"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            Monthly
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentDate(new Date())}
            className="px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded"
          >
            {periodType === "week" ? "This Week" : "This Month"}
          </button>
        </div>
      </div>

      {/* Period Navigation */}
      <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border">
        <button
          onClick={() => navigatePeriod(-1)}
          className="p-2 text-gray-600 hover:bg-gray-100 rounded-full"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>

        <div className="flex flex-col items-center">
          <h2 className="text-lg font-semibold text-gray-900">
            {getPeriodLabel()}
          </h2>
          <p className="text-sm text-gray-500">
            {periodType === "week" ? "Weekly KPIs" : "Monthly KPIs"}
          </p>
        </div>

        <button
          onClick={() => navigatePeriod(1)}
          className="p-2 text-gray-600 hover:bg-gray-100 rounded-full"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      {/* Current Period Context */}
      <div className="mt-4 p-4 bg-blue-50 rounded-lg border mb-[24px] border-blue-100">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-blue-900">Current Period</p>
            <p className="text-sm text-blue-700">
              {format(getPeriodRange().start, "MMM d, yyyy")} -{" "}
              {format(getPeriodRange().end, "MMM d, yyyy")}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm font-medium text-blue-900">KPI Status</p>
            <p className="text-sm text-blue-700">5 of 10 Submitted</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodContextSelector;
