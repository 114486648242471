import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const KpiAchievementRate = () => {
  const [selectedOption, setSelectedOption] = useState("Last 7 Days");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dateOptions = [
    "Last 7 Days",
    "Last Week",
    "Two Weeks",
    "Three Weeks",
    "Last Month",
  ];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const data = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "KPI Achievement Rate",
        data: [50, 90, 90, 55, 20],
        borderColor: "#FF8A00",
        backgroundColor: "rgba(79, 70, 229, 0.2)",
        tension: 0.4,
        borderWidth: 1,
        pointBackgroundColor: "#339CFF",
        pointBorderColor: "#fff",
        fill: false,
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: selectedOption,
          padding: { top: 20 },
        },
      },
    },
  };

  return (
    <>
      <div className="p-4 -mt-8">
        <div className="p-4 bg-white rounded-lg shadow-sm">
          <span className="flex items-center justify-between gap-4 mb-4">
            <h2 className="text-md font-bold">KPI Achievement Rate (%)</h2>

            <div className="relative w-36">
              <button
                onClick={toggleDropdown}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                type="button"
              >
                {selectedOption}
              </button>

              {dropdownOpen && (
                <ul className="absolute z-10 mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg">
                  {dateOptions.map((option) => (
                    <li
                      key={option}
                      className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${
                        selectedOption === option ? "bg-gray-100" : ""
                      }`}
                      onClick={() => handleOptionChange(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </span>
          <div style={{ height: "400px" }}>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export default KpiAchievementRate;
