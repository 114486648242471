import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./output.css";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import Dashboard from "./Pages/Dashboard";
import UpdatePassword from "./Pages/UpdatePassword";
import OTP from "./Pages/OTP";
import NotFound from "./Routes/NotFound";
import Unauthorized from "./Routes/Auth/Unauthorized";
import PrivateRoute from "./Routes/Auth/PrivateRoute";
import Managers from "./Pages/Managers";
import AuthLayout from "./Layouts/AuthLayout";
import PublicLayout from "./Layouts/PublicLayout";
import ManagerDetails from "./Routes/ManagerDetails";
import Title from "./Components/Title";
import Kpi from "./Pages/Kpi";
import Tasks from "./Pages/Tasks";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <>
      {isMobile ? (
        <Routes>
          {/* Routes under PublicLayout */}
          <Route element={<PublicLayout />}>
            <Route
              path="/"
              index
              element={
                <Title title="Login - Trakitt">
                  <Login />
                </Title>
              }
            />
            <Route
              path="/reset_password"
              element={
                <Title title="Reset Password">
                  <ResetPassword />
                </Title>
              }
            />
            <Route
              path="/otp_validation"
              element={
                <Title title="OTP Validation">
                  <OTP />
                </Title>
              }
            />
            <Route
              path="/update_password"
              element={
                <Title title="Update Password">
                  <UpdatePassword />
                </Title>
              }
            />
            <Route
              path="/unauthorized"
              element={
                <Title title="Error">
                  <Unauthorized />
                </Title>
              }
            />
          </Route>

          {/* Routes under AuthLayout */}
          <Route element={<AuthLayout />}>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Title title="Dashboard - Trakitt">
                    <Dashboard />
                  </Title>
                </PrivateRoute>
              }
            />
            <Route
              path="/managers"
              element={
                <PrivateRoute>
                  <Title title="Managers - Trakitt">
                    <Managers />
                  </Title>
                </PrivateRoute>
              }
            />
            <Route
              path="/managers/:id"
              element={
                <PrivateRoute>
                  <Title title="Manager Details - Trakitt">
                    <ManagerDetails />
                  </Title>
                </PrivateRoute>
              }
            />
            <Route
              path="/kpi"
              element={
                <PrivateRoute>
                  <Title title="KPI Dashboard - Trakitt">
                    <Kpi />
                  </Title>
                </PrivateRoute>
              }
            />
            <Route
              path="/task"
              element={
                <PrivateRoute>
                  <Title title="Tasks Overview - Trakitt">
                    <Tasks />
                  </Title>
                </PrivateRoute>
              }
            />
          </Route>

          {/* Fallback Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <div className="flex flex-col justify-center h-screen items-center">
          <img src="/sorry.svg" alt="Mobile Required" className="w-[35%]" />
          <p>Sorry! You need to be on a mobile device to view the site.</p>
        </div>
      )}
    </>
  );
}

export default App;
