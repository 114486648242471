import React from "react";
import PerformingManagers from "../Components/PerformingManagers";
import KpiAchievementRate from "../Components/KpiAchievementRate";
import FeebackSentiment from "../Components/FeebackSentiment";
import PeriodContextSelector from "../Components/PeriodContextSelector";

const Dashboard = () => {
  return (
    <>
      <div className="bg-[#F8F9FC]">
        <div className="p-4">
          <PeriodContextSelector />
        </div>
        <KpiAchievementRate />
        <FeebackSentiment />
        <PerformingManagers />
      </div>
    </>
  );
};

export default Dashboard;
