import React from "react";
import { Bar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const PerformingManagers = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const resources = [
    {
      id: 1,
      item_required: "PS5",
      date: "5/12/2024",
      quantity: 120,
    },
    {
      id: 2,
      item_required: "Laptop",
      date: "5/12/2024",
      quantity: 110,
    },
    {
      id: 3,
      item_required: "Xbox",
      date: "5/12/2024",
      quantity: 105,
    },
  ];

  const barData = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Active Care Packages",
        data: [5, 3, 6, 7, 9],
        backgroundColor: "rgba(102, 204, 102, 0.6)",
        borderColor: "rgba(102, 204, 102, 1)",
        borderWidth: 1,
      },
      {
        label: "New Packages",
        data: [2, 4, 6, 7, 2],
        backgroundColor: "rgba(102, 153, 255, 0.6)",
        borderColor: "rgba(102, 153, 255, 1)",
        borderWidth: 1,
      },
      {
        label: "Cancelled",
        data: [1, 6, 3, 7, 2],
        backgroundColor: "rgba(255, 102, 102, 0.6)",
        borderColor: "rgba(255, 102, 102, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
      },
    },
    scales: {
      x: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: false,
        },
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <>
      <div className="p-4 mb-24">
        <div className="bg-white p-4 rounded-md shadow-md">
          <span className="flex justify-between">
            <h2 className="font-bold mb-2">Care Package Summary</h2>
            <h5 className="text-btn underline">View Details</h5>
          </span>
          <div className="h-[400px]">
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
        <div className="overflow-x-auto bg-white mt-4">
          <h2 className="text-md font-bold rounded-lg mb-2 p-4">
            Resource Requests Overview
          </h2>
          <h5 className="text-btn mb-4 p-4 -mt-4 underline">View Full Table</h5>
          <table className="rounded-md shadow-md w-full">
            <thead className="shadow-md rounded-lg bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Request ID
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Item Required
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Date Needed
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {resources.map((resource) => (
                <tr key={resource.id} className="rounded-md shadow-sm">
                  <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                    {resource.id}
                  </td>
                  <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                    {resource.item_required}
                  </td>
                  <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                    {resource.date}
                  </td>
                  <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                    {resource.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PerformingManagers;
