import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const Tasks = () => {
  const [events, setEvents] = useState([
    { title: "Team Meeting", date: "2024-11-01" },
    { title: "Project Deadline", date: "2024-11-29" },
  ]);

  const handleDateClick = (info) => {
    const title = prompt("Enter Task Title");
    if (title) {
      setEvents([...events, { title, date: info.dateStr }]);
    }
  };

  const handleEventDrop = (info) => {
    const updatedEvents = events.map((event) => {
      if (event.title === info.event.title) {
        return { ...event, date: info.event.startStr };
      }
      return event;
    });
    setEvents(updatedEvents);
  };

  return (
    <div className="h-screen w-screen p-4">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        events={events}
        dateClick={handleDateClick}
        eventDrop={handleEventDrop}
        height="90%"
      />
    </div>
  );
};

export default Tasks;
