import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Line, Bar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import EditPopup from "../Components/EditPopup";
import DeletePopup from "../Components/DeletePopup";
import Popup from "../Components/Popup";
import ManagerTasks from "../Components/ManagerTasks";
import axios from "axios";
import PeriodContextSelector from "../Components/PeriodContextSelector";

const ManagerDetails = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("Last 7 Days");
  const [popup, setPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [erase, setErase] = useState(false);
  const [activeTab, setActiveTab] = useState("KPI");
  const [manager, setManager] = useState("");
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const editRef = useRef(null);
  const eraseRef = useRef(null);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const [loading, setLoading] = useState(true);

  const styles = {
    enter: "transform translate-y-full opacity-0",
    enterActive:
      "transform -translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    if (popup == true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [popup]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopup(false);
      }
    };

    if (popup) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popup]);

  useEffect(() => {
    const fetchManagers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/v1/auth/users/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredManagers = response.data.filter(
          (user) => user.user_type === "MANAGER"
        );

        setManager(filteredManagers);
      } catch (error) {
        console.error("Error fetching managers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchManagers();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="loader border-t-4 border-btn rounded-full w-12 h-12 animate-spin"></div>
      </div>
    );
  }

  if (!manager || manager.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Manager not found!</p>
      </div>
    );
  }

  const data = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "KPI Achievement Rate",
        data: [10, 40, 30, 60, 50],
        borderColor: "#FF8A00",
        backgroundColor: "rgba(79, 70, 229, 0.2)",
        tension: 0.4,
        borderWidth: 1,
        pointBackgroundColor: "#339CFF",
        pointBorderColor: "#fff",
        fill: false,
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: selectedOption,
          padding: { top: 20 },
        },
      },
    },
  };

  const barData = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Tasks Created",
        data: [5, 10, 4, 6, 10],
        backgroundColor: "#339CFF",
        borderColor: "#339CFF",
        borderWidth: 1,
      },
      {
        label: "Tasks Completed",
        data: [3, 8, 3, 6, 7],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
        },
        stacked: true,
        title: {
          display: false,
        },
      },
      x: {
        stacked: true,
      },
    },
  };

  if (!manager) {
    return <div>Manager not found!</div>;
  }
  const selectedItem = manager.find((item) => item.id === id);
  return (
    <>
      <div className="bg-[#F8F9FC] p-4">
        <div className="bg-white flex items-center justify-between p-4 mb-3 rounded-md shadow-sm">
          <span className="flex items-center gap-2">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="font-bold text-sm">Manager Performance Details</h2>
          </span>
        </div>

        <div className="mt-4 mb-4 bg-white p-4 rounded-md shadow-md">
          <div className="flex justify-evenly rounded-lg">
            <span
              className={`cursor-pointer p-3 ${
                activeTab === "KPI"
                  ? "bg-blue-500 rounded-md text-white px-8"
                  : "border rounded-md px-8"
              }`}
              onClick={() => setActiveTab("KPI")}
            >
              KPI
            </span>
            <span
              className={`cursor-pointer p-3 ${
                activeTab === "TASKS"
                  ? "bg-blue-500 rounded-md text-white px-8"
                  : "border rounded-md px-8"
              }`}
              onClick={() => setActiveTab("TASKS")}
            >
              TASKS
            </span>
          </div>
        </div>

        {selectedItem ? (
          <>
            <PeriodContextSelector />
            <div className="">
              <div className="bg-white p-6 rounded-md shadow-md">
                <span className="flex justify-between items-center">
                  <h2 className="text-xl font-bold">
                    {selectedItem.last_name} {selectedItem.first_name}
                  </h2>
                  <FontAwesomeIcon
                    onClick={() => setPopup(true)}
                    icon={faEllipsis}
                  />
                </span>
                <p>
                  <strong>Email:</strong> {selectedItem.email}
                </p>
                <p>
                  <strong>Phone Number:</strong> {selectedItem.phone}
                </p>
              </div>
            </div>

            <div className="mb-24 mt-4">
              {activeTab === "TASKS" && (
                <div className="bg-white p-4 rounded-md shadow-md">
                  <h2 className="font-bold mb-2">KPI Achievement Rate (%)</h2>
                  <div className="h-[400px]">
                    <Line data={data} options={options} />
                  </div>
                </div>
              )}
              {activeTab === "KPI" && (
                <div className="bg-white p-4 rounded-md shadow-md">
                  <h2 className="font-bold mb-2">Task Completion</h2>
                  <div className="h-[400px]">
                    <Bar data={barData} options={barOptions} />
                  </div>
                </div>
              )}
              {activeTab === "TASKS" && <ManagerTasks />}
            </div>
            <EditPopup
              selectedItem={selectedItem}
              editRef={editRef}
              setEdit={setEdit}
              styles={styles}
              edit={edit}
            />
          </>
        ) : (
          <div className="text-center mt-4">Manager details not found!</div>
        )}
      </div>

      <Popup
        setEdit={setEdit}
        setErase={setErase}
        styles={styles}
        popup={popup}
        popupRef={popupRef}
      />

      <DeletePopup
        CSSTransition={CSSTransition}
        apiUrl={apiUrl}
        toke={token}
        user_id={id}
        erase={erase}
        setErase={setErase}
        styles={styles}
        eraseRef={eraseRef}
      />
    </>
  );
};

export default ManagerDetails;
