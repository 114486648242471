import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const FeebackSentiment = () => {
  const data = {
    labels: ["Positive", "Negative"],
    datasets: [
      {
        label: "Feedback Sentiment",
        data: [70, 30],
        backgroundColor: ["#4CAF50", "#F44336"],
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 2,
      },
    ],
  };

  const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
  const positivePercentage = ((data.datasets[0].data[0] / total) * 100).toFixed(
    1
  );
  const negativePercentage = ((data.datasets[0].data[1] / total) * 100).toFixed(
    1
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 30,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  return (
    <div className="p-4">
      <div className="p-6 bg-white rounded-lg flex flex-col items-center shadow-md">
        <h2 className="text-lg font-semibold mb-4 self-start text-gray-800">
          Feedback Sentiment
        </h2>
        <div className="w-[300px] h-[300px]">
          <Pie data={data} options={options} />
        </div>

        <div className="mt-6">
          <p className="text-sm font-medium text-gray-800">
            <span className="text-green-500 font-bold">
              {positivePercentage}%
            </span>&nbsp;
            Positive Feedback
          </p>
          <p className="text-sm font-medium text-gray-800">
            <span className="text-red-500 font-bold">
              {negativePercentage}%
            </span>&nbsp;
            Negative Feedback
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeebackSentiment;
