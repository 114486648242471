import { faBullseye, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const BottomNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isActiveLink = (path) => currentPath === path;

  return (
    <>
      <div className="bg-[#E5F6FF] border backdrop-blur-md z-10 w-full h-auto p-3 px-6 fixed bottom-0">
        <span className="flex items-center justify-between">
          <Link to="/dashboard">
            <span
              className={`cursor-pointer flex flex-col items-center transition ease-in duration-300 ${
                isActiveLink("/dashboard") ? "font-bold" : ""
              }`}
            >
              <FontAwesomeIcon className="w-[21px] h-[21px]" icon={faHome} />
              <span>Home</span>
              {isActiveLink("/dashboard") && (
                <div className="mt-1 w-2 h-2 rounded-full bg-btn"></div>
              )}
            </span>
          </Link>

          <Link to="/kpi">
            <span
              className={`cursor-pointer flex flex-col items-center transition ease-in duration-300 ${
                isActiveLink("/kpi") ? "font-bold" : ""
              }`}
            >
              <img src="/bullseye.svg" alt="KPI Icon" />
              <span>KPI</span>
              {isActiveLink("/kpi") && (
                <div className="mt-1 w-2 h-2 rounded-full bg-btn"></div>
              )}
            </span>
          </Link>

          <Link to="/managers">
            <span
              className={`cursor-pointer flex flex-col items-center transition ease-in duration-300 ${
                isActiveLink("/managers") ? "font-bold" : ""
              }`}
            >
              <img src="/managers.svg" alt="Managers Icon" />
              <span>Managers</span>
              {isActiveLink("/managers") && (
                <div className="mt-1 w-2 h-2 rounded-full bg-btn"></div>
              )}
            </span>
          </Link>

          <span
            className={`cursor-pointer flex flex-col items-center transition ease-in duration-300 ${
              isActiveLink("/profile") ? "font-bold" : ""
            }`}
          >
            <img src="/task.svg" alt="Profile Icon" />
            <span>Tasks</span>
            {isActiveLink("/task") && (
              <div className="mt-1 w-2 h-2 rounded-full bg-btn"></div>
            )}
          </span>
        </span>
      </div>
    </>
  );
};

export default BottomNav;
