import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Components/Navbar";
import BottomNav from "../Components/BottomNav";

const AuthLayout = () => {
  return (
    <div>
      <Navbar />

      <main>
        <Outlet />
      </main>

      <BottomNav />
    </div>
  );
};

export default AuthLayout;
