import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const UpdatePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [showNotificationSuccess, setshowNotificationSuccess] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const savedEmail = localStorage.getItem("Email");

  const initialValues = {
    password: "",
    confirm_password: "",
    email: savedEmail || "",
  };

  //VALIDATION SCHEMA
  const Validation = yup.object().shape({
    password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    email: yup
      .string("Please use a valid email address")
      .email()
      .required("Email is required"),
  });

  //FORM SUBMISSION FUNCTION
  const submitForm = (values, { resetForm }) => {
    setIsSubmitting(true);
    axios
      .post(`${apiUrl}/api/v1/auth/reset-password/`, values, {
        withCredentials: true,
      })
      .then((response) => {
        setshowNotification(null);
        resetForm();
        localStorage.removeItem("Email");
        setshowNotificationSuccess(true);
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Error:", err);
        if (err.response) {
          const { status, data } = err.response;
          if (status === 400) {
            setshowNotification(
              data.message || "Invalid input. Please check your form data."
            );
          } else if (status === 401) {
            setshowNotification(
              data.message || "Unauthorized. Please log in again."
            );
          } else if (status === 403) {
            setshowNotification(
              data.message ||
                "You don't have permission to change the password."
            );
          } else if (status === 500) {
            setshowNotification(
              data.message || "Server error. Please try again later."
            );
          } else {
            setshowNotification(
              data.message || "An error occurred. Please try again."
            );
          }
        } else if (err.request) {
          setshowNotification(
            "No response from the server. Please check your internet connection."
          );
        } else {
          setshowNotification("An unexpected error occurred.");
        }
        setTimeout(() => {
          setshowNotification(false);
        }, 5000);
        setIsSubmitting(false);
        resetForm();
      });
  };

  // CSS TRANSITION STYLES
  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      {/* THE FORM USING FORMIK AND YUP FOR VALIDATION */}
      <div className="flex justify-center items-center w-full h-screen">
        <Formik
          initialValues={initialValues}
          validationSchema={Validation}
          onSubmit={submitForm}
        >
          <Form className="flex items-center flex-col md:w-[50%] w-[100%]">
            <img className="mb-8 w-[200px]" src="/logo-black.png" />
            <h5 className="text-primary text-2xl font-bold mb-3">
              PASSWORD CHANGE
            </h5>
            <h5 className="text-primary mb-8">
              Fill out the form to change your password.
            </h5>
            <span className="h-[1px] bg-slate-200/100"></span>

            <div className="mt-8 w-full flex items-center flex-col">
              <Field
                type="password"
                name="password"
                placeholder="Password"
                className="bg-transparent border flex items-center mt-4 focus:invalid:border-danger focus:invalid:ring-danger invalid:border-danger invalid:text-danger focus:border-focus focus:ring-1 focus:ring-focus justify-between w-[70%] outline-none rounded-md p-2"
              />
              <Field
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                className="bg-transparent border flex items-center mt-4 focus:invalid:border-danger focus:invalid:ring-danger invalid:border-danger invalid:text-danger focus:border-focus focus:ring-1 focus:ring-focus justify-between w-[70%] outline-none rounded-md p-2"
              />
              <Field
                type="email"
                name="email"
                placeholder="Email"
                readOnly="true"
                className="bg-transparent border flex items-center mt-4 focus:invalid:border-danger focus:invalid:ring-danger invalid:border-danger invalid:text-danger focus:border-focus focus:ring-1 focus:ring-focus justify-between w-[70%] outline-none rounded-md p-2"
              />
              {isSubmitting ? (
                <button type="submit" disabled className="mt-4">
                  <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </section>
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-btn mt-4 w-[70%] transition border-btn border duration-300 ease-in-out text-white hover:border hover:bg-transparent hover:text-btn flex items-center justify-center cursor-pointer rounded-lg py-2"
                >
                  <div>
                    Continue &nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </button>
              )}

              <small className="mt-12 text-primary cursor-pointer">
                &copy; Trakitt HQ, 2024.
              </small>
            </div>
          </Form>
        </Formik>
      </div>

      {/* THE NOTIFICATION FOR ERRORS */}
      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{showNotification}.</p>
          </span>
        </div>
      </CSSTransition>

      {/* THE NOTIFICATION FOR SUCCESS */}
      <CSSTransition
        in={showNotificationSuccess}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <Link to="/">
              <p className="font-light">
                Your password was updated successfully! <u>Login</u>.
              </p>
            </Link>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default UpdatePassword;
