import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faBarsStaggered,
  faEnvelope,
  faSearch,
  faSignOut,
  faTimes,
  faUserAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [search, setSearch] = useState(false);
  const [activeLink, setActiveLink] = useState("overview");
  const navRef = useRef(null);
  const mobileNav = useRef(null);
  const notificationRef = useRef(null);
  const toggleSearch = () => setSearch((prev) => !prev);
  const navigate = useNavigate();
  const searchPopupRef = useRef(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isDefaultPassword = user?.using_default_password === true;
    setHasNotification(isDefaultPassword);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("expiryTime");
    navigate("/");
  };

  const handleNotificationClick = () => {
    setShowNotification((prev) => !prev);
  };

  const styles = {
    enter: "transform -translate-x-full opacity-0",
    enterActive:
      "transform translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform -translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileNav.current && !mobileNav.current.contains(event.target)) {
        setShowNav(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileNav]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchPopupRef.current &&
        !searchPopupRef.current.contains(event.target)
      ) {
        setSearch(false);
      }
    };

    if (search) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search]);

  const checkNavVisibility = () => {
    setShowNav((prev) => !prev);
  };

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShowNav(false);
  };

  return (
    <>
      <nav
        ref={navRef}
        className="p-8 inset-x-0 top-0 z-10 flex justify-between items-center"
      >
        <button className="block cursor-pointer" onClick={checkNavVisibility}>
          <FontAwesomeIcon
            icon={showNav ? faTimes : faBarsStaggered}
            className="text-2xl"
          />
        </button>

        <img className="w-[120px]" src="/logo-black.png" />

        <span className="flex gap-[50px] items-center">
          <FontAwesomeIcon
            onClick={toggleSearch}
            icon={faSearch}
            className="text-lg"
          />
          <div className="relative">
            <button onClick={handleNotificationClick}>
              <FontAwesomeIcon icon={faBell} className="text-xl" />
            </button>
            {hasNotification && (
              <div className="absolute top-0 right-0 bg-red-500 w-2 h-2 rounded-full"></div>
            )}
          </div>
        </span>
      </nav>

      <CSSTransition
        classNames={styles}
        in={showNav}
        timeout={700}
        unmountOnExit
      >
        <div
          ref={mobileNav}
          className="bg-white fixed z-40 top-0 w-full h-full p-8"
        >
          <button
            className="md:hidden block cursor-pointer fixed top-5 right-5"
            onClick={checkNavVisibility}
          >
            <FontAwesomeIcon icon={faTimes} className="text-2xl" />
          </button>
          <div className="mt-4 flex-col flex items-center">
            <img className="w-[120px] mb-6" src="/logo-black.png" />
            <div className="w-full h-[0.1px] bg-gray-400"></div>
          </div>
          <div className="flex flex-col h-[90%] justify-between">
            <div>
              <Link
                to="/care_packages"
                onClick={() => handleNavLinkClick("care_packages")}
              >
                <div className="mt-6">
                  <span
                    className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                      activeLink === "care_packages"
                        ? "font-bold text-slate-400"
                        : ""
                    }`}
                  >
                    <img src="/care.svg" />
                    Care Packages
                  </span>
                </div>
              </Link>
              <Link
                to="/resource_request"
                onClick={() => handleNavLinkClick("resource_request")}
              >
                <div className="mt-6">
                  <span
                    className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                      activeLink === "resource_request"
                        ? "font-bold text-slate-400"
                        : ""
                    }`}
                  >
                    <img src="/resource.svg" />
                    Resource Request
                  </span>
                </div>
              </Link>
              <Link to="/reports" onClick={() => handleNavLinkClick("reports")}>
                <div className="mt-6">
                  <span
                    className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                      activeLink === "reports" ? "font-bold text-slate-400" : ""
                    }`}
                  >
                    <img src="/reports.svg" />
                    Reports and Records
                  </span>
                </div>
              </Link>
            </div>

            <button
              onClick={handleLogout}
              className="flex items-center hover:bg-red-700 bg-danger px-4 py-2 text-white rounded-md gap-4"
            >
              <FontAwesomeIcon icon={faSignOut} />
              Sign Out
            </button>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={styles}
        in={showNotification}
        timeout={700}
        unmountOnExit
      >
        <div
          ref={notificationRef}
          className="bg-white fixed z-40 top-0 w-full h-full p-8"
        >
          <button
            className="md:hidden block cursor-pointer fixed top-5 right-5"
            onClick={() => setShowNotification(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-2xl" />
          </button>
          <div className="mt-4 flex-col flex items-center">
            <h5 className="mb-4">Notifications</h5>
            <div className="w-full h-[0.1px] bg-gray-400"></div>
          </div>
          {hasNotification ? (
            <div className="mt-6">
              <span className="flex items-center gap-4">
                <FontAwesomeIcon
                  className="bg-[#339CFF] p-2 rounded-full text-white"
                  icon={faEnvelope}
                />
                Please change your password from the default one for better
                security. You can do that in your profile.
              </span>
              <hr className="mt-3" />
            </div>
          ) : (
            <div className="mt-4 text-center font-bold">Nothing here yet!</div>
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={styles}
        in={search}
        timeout={300}
        unmountOnExit
      >
        <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-25 z-50 flex justify-center items-start">
          <div
            ref={searchPopupRef}
            className="bg-white rounded-lg p-1 shadow-md w-[90%] mt-[25%] max-w-lg"
          >
            <input
              type="text"
              placeholder="Type to search..."
              className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Navbar;
