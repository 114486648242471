import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const AddKpi = ({ styles, target, setTarget }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const apiUrl = import.meta.env.VITE_API_URL;

  const styles2 = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  const kpiOptions = [
    { value: "HOURS DELIVERED", label: "HOURS DELIVERED" },
    { value: "PRIVATE CLIENTS", label: "PRIVATE CLIENTS" },
    { value: "LA CLIENTS", label: "LA CLIENTS" },
    { value: "CARE PLANS COMPLETED", label: "CARE PLANS COMPLETED" },
    { value: "CARE PLANS REVIEW", label: "CARE PLANS REVIEW" },
    { value: "STAFF TRAINING", label: "STAFF TRAINING" },
    { value: "MEDICATION AUDITS", label: "MEDICATION AUDITS" },
    { value: "COMPLAINTS", label: "COMPLAINTS" },
    { value: "QUALITY MONITORING", label: "QUALITY MONITORING" },
  ];

  const periodOptions = [
    { value: "WEEKLY", label: "Weekly" },
    { value: "MONTHLY", label: "Monthly" },
  ];

  const formatDateForInput = (date) => {
    const pad = (n) => n.toString().padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  const initialValues = {
    name: "",
    description: "",
    start_date: formatDateForInput(new Date()),
    due_date: formatDateForInput(
      new Date(new Date().setDate(new Date().getDate() + 7))
    ),
    total_expected: "",
    assignees: [],
    period: "WEEKLY",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("KPI title is required"),
    description: Yup.string().required("Description is required"),
    start_date: Yup.date().required("Start date is required"),
    due_date: Yup.date()
      .required("Due date is required")
      .min(Yup.ref("start_date"), "Due date must be after start date"),
    total_expected: Yup.number()
      .typeError("Total expected must be a number")
      .min(0, "Total expected cannot be negative")
      .required("Total expected is required"),
    assignees: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ),
    period: Yup.string()
      .oneOf(["WEEKLY", "MONTHLY"], "Invalid period")
      .required("Period is required"),
  });

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("user"))?.access;
        const response = await axios.get(`${apiUrl}/api/v1/auth/users/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredManagers = response.data.filter(
          (user) => user.user_type === "MANAGER"
        );

        const options = filteredManagers.map((manager) => ({
          value: manager.id,
          label: `${manager.first_name} ${manager.last_name}`,
        }));

        setAssigneeOptions(options);
      } catch (error) {
        console.error("Error fetching assignees:", error);
        setNotificationMessage("Failed to load assignees");
      }
    };

    fetchAssignees();
  }, [apiUrl]);

  useEffect(() => {
    if (target == true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [target]);

  const handleSubmit = async (values) => {
    setNotificationMessage(null);
    setIsSubmitting(true);

    try {
      const payload = {
        ...values,
        start_date: new Date(values.start_date).toISOString(),
        due_date: new Date(values.due_date).toISOString(),
        assignees: values.assignees.map((assignee) => assignee.value),
      };
      const token = JSON.parse(localStorage.getItem("user"))?.access;

      const response = await axios.post(
        `${apiUrl}/api/v1/tracker/targets/new`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setNotificationMessage("Target created successfully");
      setTimeout(() => setNotificationMessage(null), 3000);
      window.location.reload();
      setTarget(false);
    } catch (error) {
      console.error("Error creating target:", error);
      setNotificationMessage("Error creating target. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <CSSTransition
        in={target}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="fixed inset-0 z-50 overflow-y-scroll flex justify-center items-center bg-gray-500 bg-opacity-50">
          <div className="bg-white w-96  p-6 rounded-md shadow-lg relative">
            <FontAwesomeIcon
              onClick={() => setTarget(false)}
              icon={faTimes}
              className="float-right"
            />
            <h2 className="text-lg font-bold mb-4">Create New Target</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">
                      KPI Title
                    </label>
                    <CreatableSelect
                      options={kpiOptions}
                      onChange={(option) =>
                        setFieldValue("name", option?.value || option)
                      }
                      value={
                        kpiOptions.find(
                          (option) => option.value === values.name
                        ) || {
                          value: values.name,
                          label: values.name,
                        }
                      }
                      placeholder="Select or type a KPI title"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">
                      Description
                    </label>
                    <Field
                      name="description"
                      placeholder="Target description"
                      className="w-full border rounded-md p-2"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">
                      Start Date
                    </label>
                    <Field
                      type="datetime-local"
                      name="start_date"
                      className="w-full border rounded-md p-2"
                    />
                    <ErrorMessage
                      name="start_date"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">
                      Due Date
                    </label>
                    <Field
                      type="datetime-local"
                      name="due_date"
                      className="w-full border rounded-md p-2"
                    />
                    <ErrorMessage
                      name="due_date"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">
                      Total Expected
                    </label>
                    <Field
                      type="number"
                      name="total_expected"
                      placeholder="0"
                      className="w-full border rounded-md p-2"
                    />
                    <ErrorMessage
                      name="total_expected"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">
                      Assignees
                    </label>
                    <Select
                      options={assigneeOptions}
                      isMulti
                      onChange={(options) =>
                        setFieldValue("assignees", options)
                      }
                      value={values.assignees}
                    />
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="select-all"
                      className="mr-2"
                      onChange={(e) => {
                        const allSelected = e.target.checked;
                        setFieldValue(
                          "assignees",
                          allSelected ? assigneeOptions : []
                        );
                      }}
                      checked={
                        values.assignees.length === assigneeOptions.length &&
                        assigneeOptions.length > 0
                      }
                    />
                    <label htmlFor="select-all" className="text-sm">
                      All Managers
                    </label>
                  </div>

                  <div className="mb-4">
                    <label className="block text-sm font-medium">Period</label>
                    <Select
                      options={periodOptions}
                      onChange={(option) =>
                        setFieldValue("period", option?.value)
                      }
                      value={periodOptions.find(
                        (opt) => opt.value === values.period
                      )}
                    />
                    <ErrorMessage
                      name="period"
                      component="div"
                      className="text-danger text-sm"
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-btn w-full text-white rounded-md p-2"
                  >
                    {isSubmitting ? "Submitting..." : "Create"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={notificationMessage}
        classNames={styles2}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{notificationMessage}.</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default AddKpi;
